import React, { useState, useCallback, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { filter, find, forEach, uniqBy } from 'lodash'
import { useMount } from 'react-use'
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player'
import moment from 'moment'
import { useInView } from 'react-intersection-observer';
import useImage from "react-hook-image";

import { Layout, Drawer, AudioProgress } from '../components'
import {
    container,
    padding,
    colours,
    bgImage,
    wrapper,
    type,
    pagePaddingTop,
    introText,
    textHover,
} from '../styles/global'

import { parseACF, scrollToTop } from '../utils'
import { media, isClient } from '../styles/utils'
import { CLOUDINARY_URL, WP_MEDIA_BASE_URL } from '../constants'

const allFilter = {
    name: 'All',
    slug: 'all',
}

const Resources = (props) => {
    const queryData = useStaticQuery(query)
    const data = props.previewData || parseACF(queryData, 'allWordpressInfopages')
    const resources = queryData?.allWordpressResources?.nodes
    const [activeFilter, setActiveFilter] = useState(allFilter)
    const [filters, setFilters] = useState([])
    const [drawerActive, setDrawerActive] = useState(false)
    const [activeRadio, setActiveRadio] = useState(false)

    useMount(() => {
        getFilters()
    })
    
    useEffect(() => {
        if (!filters?.length) return

        if (props?.pageContext?.activeURLCategory) {
            const activeFilterSlug = props?.pageContext?.activeURLCategory;

            const matchFilter = find(filters, {
                slug: activeFilterSlug
            })

            if (matchFilter) {
                setActiveFilter(matchFilter)
            }
        }
    }, [filters])

    useEffect(() => {
        if (!activeFilter || !isClient()) return

        const URLSlug = activeFilter?.slug == 'all' ? '' : activeFilter?.slug
        const newURL = `/resources/${URLSlug}`

        const newState = {
            ...window.history.state,
            as: newURL,
            url: newURL,
        }

        window.history.pushState(newState, '', newURL)
    }, [activeFilter])

    const onFilterChange = (filter) => {
        setActiveFilter(filter?.slug == activeFilter?.slug ? allFilter : filter)
        scrollToTop()
    }

    const toggleDrawer = (item) => {
        setActiveRadio(item)
        setDrawerActive(true)
    }

    const itemCanDisplay = (item) => {
        if (!item) return
        return (
            activeFilter?.slug !== 'all' &&
            item?.type?.slug !== activeFilter?.slug
        )
    }

    const getFilters = () => {
        if (!resources) return

        let categories = []

        forEach(resources, (resource) => {
            const acf = JSON.parse(resource.acf_json)
            const type = acf?.type
            if (type) categories.push(type)
        })


        categories.push({
            name: 'All',
            slug: 'all',
        })

        setFilters(uniqBy(categories, 'slug'))
    }

    const renderDrawer = () => {
        const { percentComplete, duration, seek } = useAudioPosition({
            highRefreshRate: true,
        })

        const goToPosition = useCallback(
            (percentage) => {
                seek(duration * percentage)
            },
            [duration, seek]
        )

        const { togglePlayPause, stop, playing } = useAudioPlayer({
            src: '',
            format: ['mp3'],
            html5: true,
            autoplay: false,
        })

        return (
            <Drawer
                position={'right'}
                active={drawerActive}
                styles={drawerStyles}
                outsideClick={() => {
                    setDrawerActive(false)
                    stop()
                }}
            >
                <Radio>
                    <DrawerTop>
                        <Heading>{activeRadio.sub_title}</Heading>
                        <Toggle
                            onClick={() => {
                                setDrawerActive(false)
                                stop()
                            }}
                        >
                            Close
                        </Toggle>
                    </DrawerTop>

                    {(activeRadio.title_override || activeRadio.title) && (
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html:
                                    activeRadio.title_override ||
                                    activeRadio.title,
                            }}
                        />
                    )}

                    <Text
                        dangerouslySetInnerHTML={{
                            __html: `
							${activeRadio.listing_description}
						`,
                        }}
                    />

                    <AudioPlayer>
                        <Info>
                            <Heading onClick={togglePlayPause}>
                                {playing ? 'Pause' : 'Play'}
                            </Heading>
                            <Subheading>
                                {`${moment
                                    .utc(
                                        duration *
                                            1000 *
                                            (percentComplete / 100)
                                    )
                                    .format('mm:ss')}`}
                                {`/`}
                                {`${moment
                                    .utc(duration * 1000)
                                    .format('mm:ss')}`}
                            </Subheading>
                        </Info>

                        <AudioProgress
                            goToPosition={goToPosition}
                            value={percentComplete / 100}
                        />
                    </AudioPlayer>
                </Radio>
            </Drawer>
        )
    }

    const renderFilters = () => {
        let items = filters.map((filter) => {
            return (
                <Filter 
                    active={filter?.slug == activeFilter?.slug}
                >
                    <Delineator>{', '}</Delineator>

                    <span 
                        onClick={() => onFilterChange(filter)}
                    >
                        {filter.name}
                    </span>
                </Filter>
            )
        })

        return (
            <Filters>
                <Heading>Index</Heading>
                {items}
            </Filters>
        )
    }

    const renderPerspectiveDate = (item) => {
        if (!item) return

        return (
            <PerspectiveDate>
                <Heading>
                    {moment.unix(item.publishedDateUnix).format('DD.MM')}
                </Heading>
            </PerspectiveDate>
        )
    }

    const renderGridItemImage = (item) => {
        if (!item) return
        const isPerspective = item?.type?.slug == 'perspective'

        return (
            <>
                <ImageWrapper>
                    {!isPerspective && item.hero_image && (
                        <LazyImage 
                            image={item.hero_image} 
                        />
                    )}

                    {isPerspective &&
                        !item.hero_image &&
                        renderPerspectiveDate(item)}

                    {isPerspective && item.hero_image && (
                        <LazyImage 
                            image={item.hero_image} 
                        />
                    )}

                    {item.listing_description && (
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: `
								${item.listing_description}
								Read More
							`,
                            }}
                        />
                    )}
                </ImageWrapper>
            </>
        )
    }

    const renderGridItem = (item) => {
        if (!item) return

        item = {
            ...item,
            ...JSON.parse(item.acf_json),
        }

        if (itemCanDisplay(item)) return

        const isRadio = item?.type?.slug == 'radio'

        return (
            <GridItem
                key={item?.slug}
                as={!isRadio && Link}
                to={!isRadio && `/resource/${item.slug}`}
                doubleWidth={item.grid_double_width}
                onClick={() => {
                    isRadio && toggleDrawer(item)
                }}
            >
                <Info>
                    {(item.title_override || item.title) && (
                        <PostTitle>
                            {item.sub_title &&(
                                <Subheading>{item.sub_title}</Subheading>
                            )}

                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: `${
                                        item.title_override || item.title
                                    }`,
                                }}
                            />
                        </PostTitle>
                    )}
                </Info>

                {renderGridItemImage(item)}
            </GridItem>
        )
    }

    const renderGrid = () => {
        if (!resources) return

        const items = resources.map((item, i) => {
            return renderGridItem(item, i)
        })

        return <Grid>{items}</Grid>
    }

    return (
        <Layout 
            meta={data?.seo} 
            headerFloating={true} 
            breadcrumb={data.title}
        >
            <Wrapper>
                <Container>
                    {renderFilters()}
                    {renderGrid()}
                </Container>
            </Wrapper>

            {renderDrawer()}
        </Layout>
    )
}

const LazyImage = React.memo((props) => {
    const [imageURL, setImageURL] = useState(false)
    const { src, status } = useImage(imageURL);
    const { ref, inView, entry } = useInView({triggerOnce: true});

    const getImageURL = () => {
        if (!props.image) return

        // const origImage = props.image?.sizes?.full_width?.replace?.(WP_MEDIA_BASE_URL, '')
        // setImageURL(`${CLOUDINARY_URL}/w_1200,f_auto${origImage}`)

        props.image && setImageURL(props.image?.sizes?.large)
    }

    useEffect(() => {
        if (inView) getImageURL()
    }, [inView])
    
    return (
        <Image
            ref={ref}
            title={props.image?.alt}
            image={status !== 'loading' && src}
        />
    )
})


// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``

const Image = styled.div`
    ${bgImage};
    background-color: rgba(255,255,255, 0.2);
    transition: opacity 0.45s ease;

    ${props => {
        if (props.image) return css`
            background-image: url(${(props) => props.image});
        `
    }}
`

// Layout

const Wrapper = styled.div`
    background-color: ${colours.cyclus};
    height: 100%;
    padding-top: 8px;
    display: flex;
`

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    padding-top: 128px;
    flex: 1;

    ${media.phone`
		flex-direction: column;
		padding-top: 90px;
	`}
`

// Filters

const Delineator = styled.div``

const Filter = styled.div`
    display: flex;
    transition: color 0.1s ease;

    span {
        margin-left: 0.25em;
        cursor: pointer;
    }

    &:hover {
        span {
            color: ${colours.offWhite};
        }
    }

    ${(props) => {
        if (props.active)
            return css`
                span {
                    color: ${colours.offWhite} !important;
                }
            `
    }}

    ${media.phone`
		${type.body};

		&:hover {
			span {
				color: initial;
			}
		}

		span {
			margin-left: 0;
		}

		${Delineator} {
			display: none;
		}
	`}
`

const Filters = styled.div`
    display: flex;
    width: 100%;
    user-select: none;

    ${media.phone`
		position: initial;
		flex-direction: column;

		${Heading} {
			margin-bottom: 45px;
		}
	`}
`

// Grid

const Grid = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 36px);
    margin-top: 90px;
    position: relative;

    ${media.phone`
		margin-top: 45px;
		width: 100%;
	`}

    /* Hide right border */

	&::after {
        content: '';
        position: absolute;
        right: 18px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: ${colours.cyclus};

        ${media.phone`
			display: none;
		`}
    }
`

// Grid Item

const PostTitle = styled.div``
const ImageWrapper = styled.div``

const imageHeight = css`
    height: 24.64vw;

    ${media.tablet`
		height: 50vw;
	`}

    ${media.phone`
		height: 102vw;
	`}
`

const PerspectiveDate = styled.div`
    height: 100%;
    border: 2px solid black;
    background: ${colours.cyclus};

    display: flex;
    align-items: center;
    justify-content: center;

    ${Heading} {
        font-size: 60px;
        padding-top: 0.2em;
        letter-spacing: -0em;
    }
`

const GridItem = styled.div`
    flex: 0 1 25%;
    flex-basis: calc(25% - 18px);
    display: flex;
    flex-direction: column;
    margin-right: 18px;
    padding-right: 18px;
    margin-bottom: 65px;
    cursor: pointer;
    border-right: 2px solid black;

    ${Info} {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        flex: 1;
        margin-bottom: 52px;

        ${media.phone`
			min-height: 0;
			margin-bottom: 16px;

			${Text} {
				display: none;
			}
		`}
    }

    ${ImageWrapper} {
        ${imageHeight}
        position: relative;

        ${Image} {
            height: 100%;
            width: 100%;
            /* transition: all 0.45s ease; */
        }

        ${PerspectiveDate},
        ${Image} {
            /* transition: all 0.25s ease; */
        }

        ${Text} {
            opacity: 0;
            pointer-events: none;
            /* transition: opacity 0.25s ease; */
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            &,
            p,
            * {
                ${type.bodySmall};
            }
        }
    }

    &:hover {
        ${Text} {
            opacity: 1;
            pointer-events: all;
        }

        ${Image},
        ${PerspectiveDate} {
            opacity: 0;
        }
    }

    ${media.tablet`
		flex: 0 1 50%;
		flex-basis: calc(50% - 18px);

		&:hover {
			${Text} {
				opacity: 0;
			}

			${Image},
			${PerspectiveDate} {
				opacity: 1;
			}
		}
	`}

    ${media.phone`
		flex: 1 0 100%;
		padding: 0;
		margin-bottom: 50px;
		margin-right: 0;
		border-right: 0;
	`}


	/* Double Width */

	${(props) => {
        if (props.doubleWidth)
            return css`
                flex: 0 1 50%;
                flex-basis: calc(50% - 18px);
            `
    }}
`

// Drawer

const drawerStyles = css`
    background: ${colours.red};
`

const DrawerTop = styled.div``
const Toggle = styled.div``

const Radio = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    ${DrawerTop} {
        display: flex;
        justify-content: space-between;
        width: 100%;

        ${Toggle} {
            cursor: pointer;
            ${textHover}
        }
    }

    > ${Heading} {
        margin-top: 100px;
    }

    > ${Text} {
        margin-top: 150px;

        &,
        p {
            ${type.bodySmall}
        }
    }

    ${media.phone`
		> ${Heading} {
			${type.bodySmall};
		}

		${Text} {
			margin-top: 16px;
		}
	`}
`

// Audio Player (Drawer)

const AudioPlayer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;

    ${Info} {
        display: flex;
        justify-content: space-between;

        ${Heading},
        ${Subheading} {
            ${type.bodySmall};
        }
    }

    .progress-bar {
        margin-top: 20px;
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "resources" } }) {
            nodes {
                title
                acf_json
            }
        }

        allWordpressResources {
            nodes {
                acf_json
                title
                slug
                publishedDateUnix
            }
        }
    }
`

export default Resources
